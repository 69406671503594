import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "userAccount _max1280"
  }, [_c('div', {
    staticClass: "card"
  }, [_vm._m(0), _c('div', {
    staticClass: "card-content"
  }, [_c('h4', {
    staticClass: "color-blue"
  }, [_vm._v("Anzahl der Allianz Akte für SV für einen bestimmten Status")]), _c('div', {
    staticClass: "ovauto"
  }, [Object.keys(_vm.stats).length > 0 ? _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("WO\\SV")]), _c('th', [_vm._v("ST.")]), _vm._l(_objectSpread(_objectSpread({}, _vm.stats[0].sv), _vm.stats[0].sv2), function (sv, svIdx) {
    return _c('th', {
      staticClass: "tac"
    }, [_vm._v(_vm._s((_vm._svNamesFB[svIdx] || {}).name || '-'))]);
  })], 2)]), _c('tbody', _vm._l(_vm.stats, function (s, sIdx) {
    return _c('tr', [_c('td', [_vm._v(_vm._s((s.sp + '').substring(0, 4)) + "/" + _vm._s((s.sp + '').substring(4, 6)))]), _c('td', _vm._l(_vm.allowedStatus, function (stIdx, stId) {
      return _c('span', [_c('div', {
        staticClass: "bold"
      }, [_vm._v(_vm._s((_vm._statusObj[stIdx] || {}).icon || '–'))])]);
    }), 0), _vm._l(_objectSpread(_objectSpread({}, _vm.stats[0].sv), _vm.stats[0].sv2), function (sv, svIdx) {
      return _c('td', {
        staticClass: "tac"
      }, _vm._l(_vm.allowedStatus, function (stIdx, stId) {
        return _c('span', [_c('div', [_vm._v(_vm._s(((s.svForStatus || {})[svIdx] || {})[stIdx]))])]);
      }), 0);
    })], 2);
  }), 0)]) : _vm._e()]), _c('div', {
    staticClass: "spacer _vertical"
  }), _c('h4', {
    staticClass: "color-blue"
  }, [_vm._v("Anzahl der Allianz Akte für einen bestimmten Status")]), _c('div', {
    staticClass: "ovauto"
  }, [Object.keys(_vm.stats).length > 0 ? _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("WO\\Status")]), _vm._l(_vm.allowedStatus, function (stIdx, stId) {
    return _c('th', {
      staticClass: "tac"
    }, [_vm._v(_vm._s((_vm._statusObj[stIdx] || {}).icon || '–'))]);
  })], 2)]), _c('tbody', _vm._l(_vm.stats, function (s, sIdx) {
    return _c('tr', [_c('td', [_vm._v(_vm._s((s.sp + '').substring(0, 4)) + "/" + _vm._s((s.sp + '').substring(4, 6)))]), _vm._l(_vm.allowedStatus, function (stIdx, stId) {
      return _c('td', {
        staticClass: "tac"
      }, [_c('span', [_vm._v(_vm._s(s.status[stIdx])), _c('small', {
        staticClass: "color-lightgrey"
      }, [_vm._v("(" + _vm._s(s.statusCounts[stIdx]) + ")")])])]);
    })], 2);
  }), 0)]) : _vm._e()]), _c('div', {
    staticClass: "spacer _vertical"
  }), _c('h4', {
    staticClass: "color-blue"
  }, [_vm._v("Anzahl der Allianz Akte insgesamt (unabhängig vom Status) für SV")]), _c('div', {
    staticClass: "ovauto"
  }, [Object.keys(_vm.stats).length > 0 ? _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("WO\\SV")]), _vm._l(_objectSpread(_objectSpread({}, _vm.stats[0].sv), _vm.stats[0].sv2), function (sv, svIdx) {
    return _c('th', {
      staticClass: "tac"
    }, [_vm._v(_vm._s((_vm._svNamesFB[svIdx] || {}).name || 'Ohne Zuordnung'))]);
  })], 2)]), _c('tbody', _vm._l(_vm.stats, function (s, sIdx) {
    return _c('tr', [_c('td', [_vm._v(_vm._s((s.sp + '').substring(0, 4)) + "/" + _vm._s((s.sp + '').substring(4, 6)))]), _vm._l(_objectSpread(_objectSpread({}, _vm.stats[0].sv), _vm.stats[0].sv2), function (sv, svIdx) {
      return _c('td', {
        staticClass: "tac"
      }, [s.sv[svIdx] && s.sv2[svIdx] ? _c('span', [_vm._v(_vm._s(s.sv[svIdx]) + " | " + _vm._s(s.sv2[svIdx]))]) : s.sv[svIdx] ? _c('span', [_vm._v(_vm._s(s.sv[svIdx]))]) : s.sv2[svIdx] ? _c('span', [_vm._v(_vm._s(s.sv2[svIdx]))]) : _vm._e(), _c('small', {
        staticClass: "color-lightgrey"
      }, [_vm._v("(" + _vm._s(s.svCounts[svIdx]) + ")")])]);
    })], 2);
  }), 0)]) : _vm._e()]), _c('div', {
    staticClass: "spacer _vertical"
  }), _c('small', [_vm._v("Werte in den Klammern geben die Änderung zum letzten mal an.")])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Statistik")])]);
}];
export { render, staticRenderFns };